"use client";

import Image from "next/image";
import Link from "next/link";

import styles from "./styles.module.scss";

import logo from "@/assets/img/eddyrusso-full-white.png";

function HeaderComponent() {
    return (
        <header className={styles.header}>
            <div className={styles.content}>
                <div className={styles.logo}>
                    <Link href="/" passHref prefetch={false}>
                        <Image
                            priority
                            src={logo}
                            width={100}
                            height={50}
                            alt="logo"
                            quality={100}
                            className={styles.logoImg}
                        />
                    </Link>
                </div>

            </div>

        </header>
    );
}

export default HeaderComponent;
