"use client";

import styles from "./styles.module.scss";

import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
const mp3Src = "/audio/Eddy Russo - Perfumada Demais - Final.mp3";

function FooterComponent() {

    const year = new Date().getFullYear();
    return (
        <footer className={styles.footer}>
            {/* {mp3Src && (
                <div className={styles.tooltip}>
                    <p className={styles.text}>
                        Clique no Play para ouvir o novo single
                    </p>
                    <p className={styles.music}>Perfumada Demais</p>
                </div>
            )}
            <AudioPlayer
                // autoPlay
                src={mp3Src}
                layout="horizontal-reverse"
                style={{
                    backgroundColor: "transparent",
                    padding: "1rem 1rem"
                }}
                loop={true}
                volume={1}
                preload="auto"
            /> */}
            <div className={styles.content}>
                <p className={styles.text}>Eddy Russo Produções - {year}</p>
            </div>
        </footer>
    );
}

export default FooterComponent;
